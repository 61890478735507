import React from 'react'
import styled from 'styled-components'
import Layout from '../components/Layout'
import Button from '../components/shared/Button/Button'
import GeneralFormConfirmation from '../components/shared/Form/GeneralFormConfirmation'
import MainGrid from '../components/styling/MainGrid'
import { getItem } from '../util/functions'

const Confirmation = () => (
  <Layout
    activeLink="/confirmation"
    title="R&D Confirmation"
    description="R&D Confirmation"
  >
    <GeneralFormConfirmation name={getItem('name')} email={getItem('email')} />
  </Layout>
)

export default Confirmation

const Outer = styled.section`
  border-top: 1px solid #f4f4f4;
  padding: 12px 0 50px;

  @media (min-width: 768px) {
    padding-bottom: 10rem;
  }
`

const Inner = styled.section`
  width: 100%;
  max-width: 950px;
  margin: 0 auto;
`

const Text = styled.div`
  margin-top: 36px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  & h1 {
    margin-bottom: 26px;
  }

  & h6 {
    margin-bottom: 44px;
    font-weight: 300;
  }
`

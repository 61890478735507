import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import theme from '../../styling/theme'
import Button from '../Button/Button'

interface FormProps {
  name: string
  email: string
}
const GeneralFormConfirmation: FunctionComponent<FormProps> = ({
  name,
  email,
}) => {
  return (
    <Container>
      <Text>
        <Heading>Hi,</Heading>
        <Light>Thank you for getting in touch</Light>
        <ConfirmationText>
          A confirmation email has been sent. A member of our team will be in
          touch shortly.
        </ConfirmationText>

        <Button
          onClick={(e: { preventDefault: () => void }) => {
            e.preventDefault()
            history.back()
          }}
        >
          Done
        </Button>
      </Text>
    </Container>
  )
}

export default GeneralFormConfirmation

const Container = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 50px 25px;
  @media (min-width: 768px) {
    padding: 7rem 25px;
  }
`
const Text = styled.div`
  width: 100%;
  max-width: 95rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`

const Heading = styled.h2`
  text-align: center;
  word-break: break-all;
`
const Light = styled.h2``
const ConfirmationText = styled.div`
  margin: 4rem 0 5rem;
  & span {
    font-weight: bold;
  }
`
